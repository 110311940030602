
















































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import { FacilityConfigTypeEntityModel, FacilityConfigTypeQueryModel } from '@/entity-model/facility-config-type-entity';
import FacilityConfigTypeService from '@/service/facility-config-type';
import FacilityConfigTypeAttributeService from '@/service/facility-config-type-attribute';
import FacilityConfigAttributeDrawer from '@/pages/dashboard/facility-v2/facility-config/facility-config-attribute-drawer.vue';
import { FacilityBindType } from '@/model/enum';

@Component({
    components: {
        'facility-config-attribute-drawer': FacilityConfigAttributeDrawer
    }
})
export default class FacilityConfigTypeListComponent extends TableDialogFormComponent<FacilityConfigTypeEntityModel, FacilityConfigTypeQueryModel> {
    FacilityConfigTypeEntityModel = FacilityConfigTypeEntityModel;
    FacilityBindType = FacilityBindType;
    created() {
        this.initTable({
            service: FacilityConfigTypeService,
            queryModel: new FacilityConfigTypeQueryModel(),
            tableColumns: FacilityConfigTypeEntityModel.getTableColumns()
        });
        this.getList();
    }

    attributeClick(model: FacilityConfigTypeEntityModel) {
        (this.$refs.facilityConfigAttributeDrawer as FacilityConfigAttributeDrawer).drawAttributeOpen(model.id, true, model.name, FacilityConfigTypeAttributeService);
    }
}

